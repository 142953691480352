export default {
  public: [],
  member: [
    // product列表
    {
      path: 'product',
      name: 'product-list',
      component: () => import('@/modules/product/views/productList.vue'),
    },
    // product表單
    {
      path: 'product/create',
      name: 'product-create',
      meta: {
        formMode: 'create',
      },
      component: () => import('@/modules/product/views/productForm.vue'),
    },
    {
      path: 'product/update/:target',
      name: 'product-update',
      meta: {
        formMode: 'update',
      },
      component: () => import('@/modules/product/views/productForm.vue'),
    },
    // product分享
    {
      path: 'product/share',
      name: 'product-share',
      component: () => import('@/modules/product/views/productShare.vue'),
    },
    // 商品分類
    {
      path: 'product-menu',
      name: 'product-menu',
      component: () => import('@/modules/product/views/productMenuList.vue'),
    },
    {
      path: 'product-menu/create',
      name: 'product-menu-create',
      meta: {
        formMode: 'create',
      },
      component: () => import('@/modules/product/views/productMenuForm.vue'),
    },
    {
      path: 'product-menu/update/:target',
      name: 'product-menu-update',
      meta: {
        formMode: 'update',
      },
      component: () => import('@/modules/product/views/productMenuForm.vue'),
    },
    {
      path: 'product-menu/update/:target/create',
      name: 'product-menu-create-item',
      meta: {
        formMode: 'create',
      },
      component: () => import('@/modules/product/views/productMenuCreateItem.vue'),
    },
    // 商品單位
    {
      path: 'product-unit',
      name: 'product-unit',
      component: () => import('@/modules/product/views/productUnitList.vue'),
    },
    {
      path: 'product-unit/create',
      name: 'product-unit-create',
      meta: {
        formMode: 'create',
      },
      component: () => import('@/modules/product/views/productUnitForm.vue'),
    },
    {
      path: 'product-unit/update/:target',
      name: 'product-unit-update',
      meta: {
        formMode: 'update',
      },
      component: () => import('@/modules/product/views/productUnitForm.vue'),
    },
    //大量匯入商品
    {
      path: 'product-import',
      name: 'product-import',
      component: () => import('@/modules/product/views/productImport.vue'),
    },
  ],
}
